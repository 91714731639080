module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dcd679f2eda94b5781c1994379b431b1@o256641.ingest.sentry.io/5259390","environment":"production","enabled":true,"release":"0fcad687459c9f79314bfb1714f824e8bdb2140c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"19262591-c1cf-4a2e-9fdc-a534f61b0dbc","clientToken":"pub7ac384704a06266215a15dcc861a1773","site":"datadoghq.eu","env":"production","sampleRate":100,"replaySampleRate":0,"service":"booking-portal","allowedTracingOrigins":["https://gateway.kantan.co.uk"],"enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kantan-booking-portal","short_name":"booking","start_url":"/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"pPB0lduoyOPKVyEG0gEgOHvdX2oq7BLz","devKey":"pPB0lduoyOPKVyEG0gEgOHvdX2oq7BLz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3QMKQK"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
